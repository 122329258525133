import React from "react";

import banner from "../assets/pic/banner.svg";
import twitter from "../assets/pic/twitter.svg";
import facebook from "../assets/pic/facebook.svg";
import instagram from "../assets/pic/instagram.svg";
import whatsapp from "../assets/pic/whatsapp.svg";
import gmail from "../assets/pic/gmail.svg";
import "./styles/index.css";
import "./styles/footer.css";

const template = require("./json/link.json")["link"];

export default function Footer() {
    return (
        <div
            className="container-fluid pt-5 pb-4"
            style={{ background: "#0e021f" }}
        >
            <div className="container">
                <img
                    src={banner}
                    alt="Banner"
                    className="img-fluid mx-2 py-3"
                    style={{ width: "15%", minWidth: "200px" }}
                />
                <div className="py-3">
                    <a
                        href={template["twitter"]}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={twitter}
                            alt="Banner"
                            className="img-fluid mx-3"
                            style={{ maxWidth: "3%", minWidth: "30px" }}
                        />
                    </a>
                    <a
                        href={template["facebook"]}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={facebook}
                            alt="Banner"
                            className="img-fluid mx-3"
                            style={{ maxWidth: "3%", minWidth: "30px" }}
                        />
                    </a>

                    <a
                        href={template["instagram"]}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={instagram}
                            alt="Banner"
                            className="img-fluid mx-3"
                            style={{ maxWidth: "3%", minWidth: "30px" }}
                        />
                    </a>
                    {/* <a href={template['whatsapp']} target="_blank" rel="noreferrer">
                        <img
                            src={whatsapp}
                            alt="Banner"
                            className="img-fluid mx-3"
                            style={{ maxWidth: '3%', minWidth: '30px' }}
                        />
                    </a> */}
                    <a
                        href={template["email"]}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={gmail}
                            alt="Banner"
                            className="img-fluid mx-3"
                            style={{ maxWidth: "3%", minWidth: "30px" }}
                        />
                    </a>
                </div>
                {/* <p className="footer-text mx-3 pt-2">
                    <a href={template['whatsapp']} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                        <span style={{ fontWeight: 600, color: 'white' }}>Tel: </span>
                        +852 9367 2362
                    </a>

                </p> */}
                <p className="footer-text mx-3">
                    <a
                        href={template["email"]}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span style={{ fontWeight: 600, color: "white" }}>
                            Email:{" "}
                        </span>
                        mintnityhk@gmail.com
                    </a>
                </p>
                <br />
                <p className="footer-text mx-3">Copyright © 2022, Mintnity</p>
            </div>
        </div>
    );
}
